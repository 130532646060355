import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Divider, styled, TableBody } from '@mui/material';
import { UserModel, UserProfile } from '@src/types/models';
import Counters from '@components/UserCard/Counters';
import { UserStatuses } from '@src/types';
import { UserCardFormData } from '@components/UserCard/types';
import CardTable from '@components/CardTable';
import UserInfoCommonHeader from '@components/UserCard/UserInfoCommonHeader';
import UserInfoMidPart from '@components/UserCard/UserInfoMidPart';
import AvatarContainer from '@components/UserCard/AvatarContainer';
import CardHeader from '@components/UserCard/CardHeader';
import RestrictionHistoryList from '@components/Restriction/RestrictionHistoryList';

type UserInfoTabProps = BoxProps & {
  user: UserModel;
};

const UserInfoTab = styled(({ user, ...props }: UserInfoTabProps) => {
  const [profile, setProfile] = useState<UserProfile>(Object.assign(user.profile || {}));
  const [formData, setFormData] = useState<UserCardFormData>(Object.assign(profile));
  const [userStatus, setUserStatus] = useState<UserStatuses>(UserStatuses.ACTIVE);
  const [editable, setEditable] = useState<boolean>(false);

  const saveProfile = () => {
    setEditable(false);
    setProfile(formData);
  };

  const cancelChanges = () => {
    setEditable(false);
    setFormData(profile);
  };

  useEffect(() => {
    if (user.is_purged) {
      setUserStatus(UserStatuses.DELETE);
    } else if (!user.is_active) {
      setUserStatus(UserStatuses.BLOCK);
    } else {
      setUserStatus(UserStatuses.ACTIVE);
    }
  }, [user]);

  const handleRemoveAvatar = () => {
    setFormData(prevState => ({ ...prevState, picture: null }));
  };

  const handleRestoreAvatar = () => {
    setFormData(prevState => ({ ...prevState, picture: profile.picture }));
  };

  return (
    <Box {...props}>
      <CardHeader editable={editable} setEditable={setEditable} />
      <Box sx={{ px: 3, pb: 2, flexGrow: 1, overflow: 'auto' }}>
        <AvatarContainer
          user={user}
          profile={profile}
          formData={formData}
          editable={editable}
          onRemoveAvatar={handleRemoveAvatar}
          onRestoreAvatar={handleRestoreAvatar}
          setFormData={setFormData}
        />
        <CardTable>
          <TableBody>
            <UserInfoCommonHeader user={user} editable={editable} saveProfile={saveProfile} cancelChanges={cancelChanges} />
            <UserInfoMidPart user={user} />
          </TableBody>
        </CardTable>
        <RestrictionHistoryList user={user} />
        <Divider variant="fullWidth" />
        <Counters userId={user.id} />
      </Box>
    </Box>
  );
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.MuiTableCell-root': {
    verticalAlign: 'top',
  },
}));

export default UserInfoTab;
